import { Component, OnInit } from '@angular/core';
import { navItemsAdmin } from '../../_nav_admin';
import { navItemsEmpleado } from '../../_nav_empleado';
import { navItemsTercero } from '../../_nav_tercero';
import { environment } from './../../../environments/environment';
import { BnNgIdleService } from 'bn-ng-idle';

import { AuthService } from './../../core/servicios/auth.service';
import { Sessiontercero } from './../../core/interfaces/sessiontercero';
import { Sessionadmin } from './../../core/interfaces/sessionadmin';
import { Sessionempleado } from './../../core/interfaces/sessionempleado';
import { Empleado } from './../../core/interfaces/empleado';
import { Notificacion } from './../../core/interfaces/notificacion';
import { NotificacionService } from './../../core/servicios/notificacion.service';
import { QueryService } from './../../core/servicios/-query.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  // public navItems = navItems;
  public navItems;
  public urlImagen: string = environment.urlImagen;
  public perfil: string;
  public nombre: string;
  public sessiontercero: Sessiontercero;
  public sessionadmin: Sessionadmin;
  public sessionempleado: Sessionempleado;
  public empleado: Empleado;
  public array: Notificacion[];

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  constructor(
    private authService: AuthService,
    private bnIdle: BnNgIdleService,
    private notificacionService: NotificacionService,
    private queryService: QueryService,
  ) { }

  ngOnInit(): void {
    this.perfil = this.authService.validar();
    // console.log(this.perfil);
    if (this.perfil === 'Tercero') {
      this.navItems = navItemsTercero;
      this.sessiontercero = this.authService.obtenerTercero();
      this.nombre = this.sessiontercero.nombre;
    } else if (this.perfil === 'Admin') {
      this.navItems = navItemsAdmin;
      this.sessionadmin = this.authService.obtenerAdmin();
      // console.log(this.sessionadmin);
      this.nombre = this.sessionadmin.usuario;
    } else if (this.perfil === 'Empleado') {
      this.navItems = navItemsEmpleado;
      this.sessionempleado = this.authService.obtenerEmpleado();
      // console.log(this.sessionempleado);
      this.nombre = this.sessionempleado.correoelectronico;

      // const sessionempleado: Sessionempleado = this.authService.obtenerEmpleado();

      this.queryService.query_1014(this.sessionempleado.fkempleado).subscribe((empleado: Empleado[]) => {
        this.empleado = empleado[0];

        // this.notificacionService.getAllBy(this.empleado.departamento).subscribe(response => {
        this.notificacionService.getAllBy(this.empleado.id).subscribe(response => {
          // console.log(response);
          this.array = response;
          // this.array = this.array.filter(item => item.departamento.toLocaleLowerCase().includes(this.empleado.departamento.toLocaleLowerCase()));
          this.array = this.array.filter(item => item.departamento === this.empleado.id);
        });
      });
    }

    this.bnIdle.startWatching(6000).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        // console.log('session expired');
        this.authService.salir();
      }
    });

    // if (this.authService.validar() === 'Empleado') {
    //   const sessionempleado: Sessionempleado = this.authService.obtenerEmpleado();

    //   this.queryService.query_1014(sessionempleado.fkempleado).subscribe((empleado: Empleado[]) => {
    //     this.empleado = empleado[0];

    //     this.notificacionService.getAllBy(this.empleado.departamento).subscribe(response => {
    //       // console.log(response);
    //       this.array = response;
    //       this.array = this.array.filter(item => item.departamento.toLocaleLowerCase().includes(this.empleado.departamento.toLocaleLowerCase()));
    //     });
    //   });
    // }
  }

  public logout() {
    this.authService.salir();
  }
}
