import { INavData } from '@coreui/angular';

export const navItemsEmpleado: INavData[] = [
  {
    name: 'Tablero de Servicios',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    title: true,
    name: 'Módulos'
  },
  {
    name: 'Contrato',
    url: '/contrato',
    icon: 'icon-cursor'
  },
  {
    name: 'Petición',
    url: '/peticion',
    icon: 'icon-menu',
  },
  {
    name: 'Cita',
    url: '/cita',
    icon: 'icon-menu',
  },
  {
    name: 'Desocupación',
    url: '/desocupacion',
    icon: 'icon-cursor'
    },
  {
    name: 'Contactos',
    url: '/contactos',
    icon: 'icon-cursor'
  },
  {
    name: 'Masivos',
    url: '/masivos',
    icon: 'icon-cursor'
  },
  {
    name: 'Simulador',
    url: '/simulador',
    icon: 'icon-cursor'
  },

];
