import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

// import { LocalStorageService } from './-local-storage.service';
import { Admin } from './../../core/interfaces/admin';
import { Accion } from './../../core/interfaces/accion';
import { Empleado } from './../../core/interfaces/empleado';
import { Tercero } from './../../core/interfaces/tercero';
import { Inmueble } from './../../core/interfaces/inmueble';
// import { Departamento } from './../../core/interfaces/departamento';
import { Programacioncita } from './../../core/interfaces/programacioncita';
import { Programaciondesocupacion } from './../../core/interfaces/programaciondesocupacion';


@Injectable({
  providedIn: 'root'
})
export class QueryService {

  // private headers;

  constructor(
    private http: HttpClient,
    // private localstorageService: LocalStorageService,
  ) {
    // try {
    //   this.headers = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json, text/plain, */*',
    //     'Accept-Encoding': 'gzip, deflate, br',
    //     'Accept-Language': 'es-ES,es;q=0.9',
    //     'Connection': 'keep-alive',
    //     // 'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Accept, Origin, Authorization, HTTP_JWT, tipo, fk',
    //     'JWT': this.localstorageService.get('QYfRuxeadphBcSuOAczF').toString(),
    //     'tipo': this.localstorageService.get('qFUNseVbOFIMuWyQSdxC').toString(),
    //     'fk': this.localstorageService.get('eVBEvatmKiVlokbfjfwJ').toString(),
    //     'correoelectronico': this.localstorageService.get('CBIfXgisPiSqolrJrNFd').toString(),
    //   });
    // } catch (error) {
    // }

  }



  public query_1000(value: any): Observable<any[]> { // Maestro
    return this.http.post<any[]>(environment.urlApi + '/Query/1000', { campo: value });
  }

  public query_1001(value1: any, value2: any): Observable<Admin> { // Loguin de Admin
    return this.http.post<Admin>(environment.urlApi + '/Query/1001', { usuario: value1, contrasena: value2 });
  }

  public query_1002(value1: any, value2: any): Observable<object> { // Crear SessionAdmin
    return this.http.post(environment.urlApi + '/Query/1002', { fkadmin: value1, usuario: value2 });
  }

  public query_1003(value1: any, value2: any, value3: any, value4: any): Observable<Accion> { // Permisos en Accion segun perfil
    return this.http.post<Accion>(environment.urlApi + '/Query/1003', { modulo: value1, fk: value2, accion: value3, perfil: value4 });
  }

  public query_1004(value1: any, value2: any): Observable<Empleado> { // Loguin de Empleado
    return this.http.post<Empleado>(environment.urlApi + '/Query/1004', { correoelectronico: value1, contrasena: value2 });
  }

  public query_1005(value1: any, value2: any): Observable<object> { // Crear SessionEmpleado
    return this.http.post(environment.urlApi + '/Query/1005', { fkempleado: value1, correoelectronico: value2 });
  }

  public query_1006(value1: any): Observable<Tercero> { // Buscar Tercero SIMI
    return this.http.post<Tercero>(environment.urlApi + '/Query/1006', { nit: value1 });
  }

  public query_1007(value1: any, value2: any, value3: any,
    value4: any, value5: any, value6: any,
    value7: any, value8: any, value9: any,
    value10: any, value11: any, value12: any): Observable<object> { // Crear SessionTercero
    return this.http.post(environment.urlApi + '/Query/1007', {
      nit: value1, nombre: value2, nombre1: value3,
      nombre2: value4, apellido1: value5, apellido2: value6,
      correoelectronico: value7, direccion: value8, telefono: value9,
      celular: value10, razonsocial: value11, tipotercero: value12
    });
  }

  public query_1008(value1: any, value2: any, value3: any, value4: any, value5: any): Observable<object> { // Actualizar Tercero
    return this.http.post(environment.urlApi + '/Query/1008', {
      nit: value1, direccion: value2, email1: value3, telefono: value4, cel1: value5
    });
  }

  public query_1009(): Observable<Inmueble[]> { // Inmuebles de Tercero
    return this.http.post<Inmueble[]>(environment.urlApi + '/Query/1009', { value: null });
  }

  public query_1010(value1: any): Observable<Programacioncita[]> { // Citas Disponibles
    return this.http.post<Programacioncita[]>(environment.urlApi + '/Query/1010', { tipo: value1 });
  }

  public query_10100(value1: any, value2: any, value3: any): Observable<Programaciondesocupacion[]> { // Citas Disponibles
    return this.http.post<Programaciondesocupacion[]>(environment.urlApi + '/Query/10100', { tipo: value1, fechaterminacioncontratoINICIO: value2, fechaMes: value3 });
  }

  public query_101000(value1: any, value2: any): Observable<Programaciondesocupacion[]> { // Citas Disponibles
    return this.http.post<Programaciondesocupacion[]>(environment.urlApi + '/Query/101000', { tipo: value1, fechaterminacioncontratoINICIO: value2 });
  }

  public query_1011(): Observable<any[]> { // Maestro
    return this.http.post<any[]>(environment.urlApi + '/Query/1011', { value: null });
  }

  public query_1012(value: any): Observable<any[]> { // Maestro con Valor
    return this.http.post<any[]>(environment.urlApi + '/Query/1012', { tipo: value });
  }

  public query_1014(value: any): Observable<any[]> { // Traer datos Empleado
    return this.http.post<any[]>(environment.urlApi + '/Query/1014', { fk: value });
  }

  public query_1015(value: any): Observable<any[]> { // Traer datos Maestro
    return this.http.post<any[]>(environment.urlApi + '/Query/1015', { departamento: value });
  }

  public query_1016(value1: any, value2: any, value3: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1016', { reporte: value1, fecha_inicio: value2, fecha_fin: value3 });
  }

  public query_1017(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1017', { value: null });
  }

  public query_1018(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1018', { departamento: value });
  }

  public query_1019(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1019', { value: null });
  }

  public query_1020(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1020', { contacto: value });
  }

  public query_1021(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1021', { id: value });
  }


  public query_10210(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/10210', { id: value });
  }

  public query_1022(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1022', { fecha_inicio: value1, fecha_fin: value2 });
  }



  public masivos(value1: any, value2: any, value3: any): Observable<any> {
    const headers = { 'TOKEN': 'J5OOp28pK21MIZdGqf' };
    return this.http.post<any>('https://test.gespronapolo.com/notificacion_mail/masivos.php', { usuarios: value1, titulo: value2, contenido: value3 }, { headers });
  }

  // public notificacion_sms(value1: any, value2: any, value3: any): Observable<any> { 
  //   return this.http.post<any>('https://test.gespronapolo.com/notificacion_sms/notificacion_sms.php', { mensaje: value1, cel: value2, departamento: value3 });
  // }

  public notificacion_mail(value1: any, value2: any, value3: any): Observable<any> {
    const headers = { 'TOKEN': 'J5OOp28pK21MIZdGqf' };
    return this.http.post<any>('https://test.gespronapolo.com/notificacion_mail/notificacion_mail.php', { email: value1, mensaje: value2, departamento: value3 }, { headers });
  }

  public notificacion_sms(value1: any, value2: any, value3: any): Observable<any> {
    const headers = { 'TOKEN': 'J5OOp28pK21MIZdGqf' };
    return this.http.post<any>('https://test.gespronapolo.com/notificacion_sms/notificacion_sms.php', { usuarios: value1, contenido: value2 }, { headers });
    // return this.http.get<any>('http://apimasivos.colombiared.com.co/Api/get/send.php?username=PruebaAPI&password=12345678&' +
    //   'to=' + value1 + '&' +
    //   'text=' + value2 + '&from=TEST&' +
    //   'coding=0&dlr-mask=8&dlr-url=http%3A%2F%2Fmi.server.com%2Fnotifica.php%3Fidenvio%3D7584%26remitente%3D%25p%26tel%3D%25P%26estado%3D%25d');
  }



  // public masivos(value1: any, value2: any, value3: any): Observable<any> {
  //   return this.http.post<any>('https://test.gespronapolo.com/notificacion_mail/masivos.php', { usuarios: value1, titulo: value2, contenido: value3 });
  // }

  // public notificacion_sms(value1: any, value2: any, value3: any): Observable<any> { 
  //   return this.http.post<any>('https://test.gespronapolo.com/notificacion_sms/notificacion_sms.php', { mensaje: value1, cel: value2, departamento: value3 });
  // }

  // public notificacion_sms(value1: any, value2: any, value3: any): Observable<any> {
  //   return this.http.get<any>('http://apimasivos.colombiared.com.co/Api/get/send.php?username=PruebaAPI&password=12345678&' + 
  //   'to=' + value2 + '&' +
  //   'text=' + value1 + '&from=TEST&'+
  //   'coding=0&dlr-mask=8&dlr-url=http%3A%2F%2Fmi.server.com%2Fnotifica.php%3Fidenvio%3D7584%26remitente%3D%25p%26tel%3D%25P%26estado%3D%25d');
  // }


}
