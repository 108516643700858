<app-header [navbarBrandRouterLink]="['/dashboard']" [fixed]="true"
  [navbarBrandFull]="{src: urlImagen + '/logo.png', width: 92, height: 26, alt: 'Logo'}"
  [navbarBrandMinimized]="{src: urlImagen + '/favicon.png', width: 32, height: 32, alt: 'Logo'}" [sidebarToggler]="''"
  [asideMenuToggler]="''">
  <!-- <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3">
      <a class="nav-link" routerLink="/dashboard">Dashboard</a>
    </li>
  </ul> -->
  <!-- <ul class="nav navbar-nav ml-auto">
  </ul> -->
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="btn btn-sm btn-light mr-3" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
        aria-expanded="false" dropdownToggle (click)="false" *ngIf="perfil.includes('Empleado') && array">
        <i class="fa fa-list"></i> Notificaciones ({{array.length}})
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <!-- <div class="dropdown-header text-center"><strong>Account</strong></div> -->
        <a class="dropdown-item" *ngFor="let detail of array" [routerLink]="[detail.enlace]">
          [{{detail.fecha}}] {{detail.texto}}
        </a>
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span
            class="badge badge-success">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span
            class="badge badge-danger">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comment<span
            class="badge badge-warning">42</span></a>
        <div class="dropdown-header text-center"><strong>Settings</strong></div>
        <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
        <a class="dropdown-item" href="#"><i class="fa fa-wrench"></i> Setting</a>
        <a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-dark">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span
            class="badge badge-primary">42</span></a>
        <div class="divider"></div>
        <a class="dropdown-item" href="#"><i class="fa fa-shield"></i> Lock account</a>
        <a class="dropdown-item" href="#"><i class="fa fa-lock"></i> Logout</a> -->
      </div>
      <span class="mr-3"><b class="text-white">MA053-18</b></span>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar *ngIf="!perfil.includes('Tercero')" #appSidebar [fixed]="true" [display]="'lg'"
    [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <main class="main">
    <cui-breadcrumb>
      <div style="margin-right: auto;" class="col-12 col-md-6 mb-2">Bienvenido <b>{{nombre}}</b></div>
      <div class="col-12 col-md-6 mb-2" style="display: flex;">
        <div class="btn-group mr-2" role="group" style="margin-left: auto;">
          <button type="button" class="btn btn-sm btn-danger" (click)="logout()">
            <i class="fa fa-sign-out"></i> Cerrar sesión
          </button>
          <a class="btn btn-sm btn-info"
            href="https://api.whatsapp.com/send?phone=573106965130&text=Hola,%20deseo%20recibir%20informaci%C3%B3n"
            target="_blank">
            <i class="fa fa-info-circle"></i> Ayuda
          </a>
        </div>
      </div>
      <div class="col-md-12 d-block d-sm-block d-md-none"
        *ngIf="perfil.includes('Admin') || perfil.includes('Empleado')">
        <button type="button" class="btn btn-block btn-primary" routerLink="/dashboard">Tablero de servicios</button>
      </div>
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<app-footer>
  <style>
    #btnmov {
      transform: scale(1);
      animation: mymove 3s infinite;
    }

    @keyframes mymove {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.15);
      }

      100% {
        transform: scale(1);
      }
    }
  </style>
  <a href="https://api.whatsapp.com/send?phone=573106965130&text=Hola!%20necesito%20informaci%C3%B3n.%20Mi%20nombre%20es%3A"
    target="_blank">
    <!-- <img *ngIf="this.perfil === 'Tercero'" class="el-image" [src]="urlImagen + 'asistencia.png'"  width="auto" height="400px"
      style="
        position: fixed;
        bottom: 0px;
        right: 100px;
        z-index: 999;"> --->
    <img id="btnmov" class="el-image" alt="" src="https://test.gespronapolo.com/wppatencion-2.png" width="auto" height="55px"
      style="
        position: fixed;
        bottom: 100px;
        right: 25px;
        z-index: 9999;">
  </a>
</app-footer>