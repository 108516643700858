<div class="app-body" style="background: #003F99 !important;">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <span class="ml-auto float-right"><b class="text-white px-3 mb-5">MA053-18</b></span>
      </div>
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <img [src]="urlImagen + 'logo.png'" class="img-fluid mb-5" width="345">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <h1>Login</h1>
                <p class="text-muted">Iniciar sesión en su cuenta</p>
                <div class="row">
                  <div class="col-sm-12 col-lg-4 my-1">
                    <button type="button" class="btn btn-block btn-light px-2" (click)="isTercero = true"
                      *ngIf="!isAdmin && !isEmpleado && !isTercero">Propietario / Arrendatario</button>
                  </div>
                  <div class="col-sm-12 col-lg-4 my-1">
                    <button type="button" class="btn btn-block btn-light px-2" (click)="isEmpleado = true"
                      *ngIf="!isAdmin && !isEmpleado && !isTercero">Empleado</button>
                  </div>
                  <div class="col-sm-12 col-lg-4 my-1">
                    <button type="button" class="btn btn-block btn-light px-2" (click)="isAdmin = true"
                      *ngIf="!isAdmin && !isEmpleado && !isTercero">Administrador</button>
                  </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="login($event)" *ngIf="isAdmin || isEmpleado || isTercero">
                  <div class="input-group mb-3" *ngIf="isAdmin || isEmpleado">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input formControlName="email" type="text" class="form-control" placeholder="Correo electrónico"
                      autocomplete="username" [required]="isAdmin || isEmpleado">
                  </div>
                  <div class="input-group mb-3" *ngIf="isTercero">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input formControlName="nit" type="text" class="form-control" placeholder="Documento de identidad"
                      autocomplete="id" [required]="isAdmin || isEmpleado">
                  </div>
                  <div class="input-group mb-4" *ngIf="isAdmin || isEmpleado">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input formControlName="password" type="password" class="form-control" placeholder="Password"
                      autocomplete="current-password" [required]="isAdmin || isEmpleado">
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <button [disabled]="form.invalid" type="submit"
                        class="btn btn-block btn-primary px-4">Ingresar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>