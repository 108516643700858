import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
// import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
// import { RegisterComponent } from './views/register/register.component';

import { GuardianGuard } from './guardian.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: 'login/:enlace',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'accion',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/accion/accion.module').then(m => m.AccionModule)
      },
      {
        path: 'admin',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'cita',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/cita/cita.module').then(m => m.CitaModule)
      },
      {
        path: 'empleado',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/empleado/empleado.module').then(m => m.EmpleadoModule)
      },
      {
        path: 'masivos',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/masivos/masivos.module').then(m => m.MasivosModule)
      },
      {
        path: 'notificacionpeticion',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/notificacionpeticion/notificacionpeticion.module').then(m => m.NotificacionpeticionModule)
      },
      {
        path: 'peticion',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/peticion/peticion.module').then(m => m.PeticionModule)
      },
      {
        path: 'seguimientopeticion',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/seguimientopeticion/seguimientopeticion.module').then(m => m.SeguimientopeticionModule)
      },
      {
        path: 'maestro',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/maestro/maestro.module').then(m => m.MaestroModule)
      },
      {
        path: 'tercero',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/tercero/tercero.module').then(m => m.TerceroModule)
      },
      {
        path: 'simulador',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/simulador/simulador.module').then(m => m.SimuladorModule)
      },
      {
        path: 'departamento',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/departamento/departamento.module').then(m => m.DepartamentoModule)
      },
      {
        path: 'reporte',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/reporte/reporte.module').then(m => m.ReporteModule)
      },
      {
        path: 'semaforo',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/semaforo/semaforo.module').then(m => m.SemaforoModule)
      },
      {
        path: 'contactos',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/contactos/contactos.module').then(m => m.ContactosModule)
      },
      {
        path: 'desocupacion',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/desocupacion/desocupacion.module').then(m => m.DesocupacionModule)
      },
      {
        path: 'programaciondesocupacion',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/programaciondesocupacion/programaciondesocupacion.module').then(m => m.ProgramaciondesocupacionModule)
      },
      {
        path: 'contrato',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/contrato/contrato.module').then(m => m.ContratoModule)
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
