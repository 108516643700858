import { INavData } from '@coreui/angular';

export const navItemsTercero: INavData[] = [
  {
    name: 'Tablero de Servicios',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    title: true,
    name: 'Modulos'
  },

];
