import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { map, tap } from 'rxjs/operators';

import { AuthService } from './core/servicios/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuardianGuard implements CanActivate {
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  constructor(
    private authService: AuthService,
    private router: Router
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.validar() === 'Admin' ||
      this.authService.validar() === 'Empleado' ||
      this.authService.validar() === 'Tercero') {
      return true;
    } else {
      this.router.navigate(['/login']);
    }

  }

}
