import { INavData } from '@coreui/angular';

export const navItemsAdmin: INavData[] = [
  {
    name: 'Tablero de Servicios',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    title: true,
    name: 'Módulos'
  },
  {
    name: 'Usuarios',
    url: '/usuarios',
    icon: 'icon-menu',
    children: [
      {
        name: 'Admin',
        url: '/admin',
        icon: 'icon-user'
      },
      {
        name: 'Empleado',
        url: '/empleado',
        icon: 'icon-user'
      },
    ]
  },
  {
    name: 'General',
    url: '/general',
    icon: 'icon-menu',
    children: [
      {
        name: 'Acción',
        url: '/accion',
        icon: 'icon-settings'
      },
      {
        name: 'Maestro',
        url: '/maestro',
        icon: 'icon-settings'
      },
      {
        name: 'Departamento',
        url: '/departamento',
        icon: 'icon-settings'
      },
      {
        name: 'Contactos',
        url: '/contactos',
        icon: 'icon-settings'
      },
      {
        name: 'Programación Desocupación',
        url: '/programaciondesocupacion',
        icon: 'icon-settings'
      },
    ]
  },
  {
    name: 'Contrato',
    url: '/contrato',
    icon: 'icon-cursor'
  },
  {
    name: 'Petición',
    url: '/peticion',
    icon: 'icon-cursor'
  },
  {
    name: 'Cita',
    url: '/cita',
    icon: 'icon-cursor'
  },
  {
    name: 'Desocupación',
    url: '/desocupacion',
    icon: 'icon-cursor'
  },
  {
    name: 'Masivos',
    url: '/masivos',
    icon: 'icon-cursor'
  },
  {
    name: 'Simulador',
    url: '/simulador',
    icon: 'icon-cursor'
  },
  {
    name: 'Reporte',
    url: '/reporte',
    icon: 'icon-cursor'
  },
  {
    name: 'Semáforo ',
    url: '/semaforo',
    icon: 'icon-cursor'
  },

];
