import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Notificacion } from './../interfaces/notificacion';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  public arrayObservable = new BehaviorSubject<Notificacion[]>(null);
  public array$ = this.arrayObservable.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  public getAll(): Observable<Notificacion[]> {/**GetAll */
    return this.http.get<Notificacion[]>(environment.urlApi + '/Notificacion');
  }

  public getAllBy(departamento: any): Observable<Notificacion[]> {/**GetAllBy */
    return this.http.get<Notificacion[]>(environment.urlApi + '/Notificacion/By/' + departamento);
  }

  public post(object: Notificacion): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/Notificacion', object);
  }

  public get(id: number): Observable<Notificacion> {/**GetOne */
    return this.http.get<Notificacion>(environment.urlApi + '/Notificacion/' + id);
  }

  public put(object: Notificacion): Observable<object> {/**Update */
    return this.http.put(environment.urlApi + '/Notificacion/' + object.id, object);
  }

  public delete(object: Notificacion): Observable<object> {/**Delete */
    return this.http.delete(environment.urlApi + '/Notificacion/' + object.id);
  }
}