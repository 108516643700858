import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';

import { AuthService } from './../../core/servicios/auth.service';
import { Admin } from './../../core/interfaces/admin';
import { Empleado } from './../../core/interfaces/empleado';
import { Tercero } from './../../core/interfaces/tercero';
import { environment } from './../../../environments/environment';
import { QueryService } from './../../core/servicios/-query.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  public urlImagen: string = environment.urlImagen;
  public isAdmin = false;
  public isEmpleado = false;
  public isTercero = false;
  public isLogin = false;
  public enlace: string;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private queryService: QueryService,
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.enlace = params.enlace;

      if (this.enlace === 'peticion-new-peticion' ||
        this.enlace === 'desocupacion-new-desocupacion' ||
        this.enlace === 'cita-new-cita-virtual' ||
        this.enlace === 'cita-new-cita-presencial') {
        this.isTercero = true;
      }
    });
  }

  login(event: Event) {
    // alert('login');
    event.preventDefault();
    if (this.form.valid) {
      const value = this.form.value;

      this.queryService.query_1001('' + value.email, '' + value.password).subscribe(
        (admin: Admin) => {
          if (admin.id) {
            Swal.fire({
              icon: 'success',
              html: 'La verificación de tu identidad ha sido exitosa ¡Bienvenido!',
              showConfirmButton: false,
              timer: 3000
            });

            this.authService.crearAdmin(admin);
          } else {
            // Swal.fire({
            //   icon: 'error',
            //   html: 'ERROR, no pudimos validar tu usuario y contraseña',
            //   showConfirmButton: false,
            //   timer: 3000
            // });
            this.loginEmpledo(event);
          }
        },
        error => {
          // Swal.fire({
          //   icon: 'error',
          //   html: 'ERROR, no pudimos validar tu usuario y contraseña',
          //   showConfirmButton: false,
          //   timer: 3000
          // });
          this.loginEmpledo(event);
        },
        () => {
        }
      );
    }
  }

  loginEmpledo(event: Event): void {
    // alert('loginEmpledo');
    event.preventDefault();
    if (this.form.valid) {
      const value = this.form.value;

      this.queryService.query_1004('' + value.email, '' + value.password).subscribe(
        (empleado: Empleado) => {
          if (empleado.id) {
            Swal.fire({
              icon: 'success',
              html: 'La verificación de tu identidad ha sido exitosa ¡Bienvenido!',
              showConfirmButton: false,
              timer: 3000
            });

            this.authService.crearEmpleado(empleado);
          } else {
            // Swal.fire({
            //   icon: 'error',
            //   html: 'ERROR, no pudimos validar tu usuario y contraseña',
            //   showConfirmButton: false,
            //   timer: 3000
            // });
            this.loginTercero(event);
          }
        },
        error => {
          // Swal.fire({
          //   icon: 'error',
          //   html: 'ERROR, no pudimos validar tu usuario y contraseña',
          //   showConfirmButton: false,
          //   timer: 3000
          // });
          this.loginTercero(event);
        },
        () => {
        }
      );
    }
  }

  loginTercero(event: Event): void {
    // alert('loginTercero');
    event.preventDefault();
    if (this.form.valid) {
      const value = this.form.value;

      this.queryService.query_1006('' + value.nit).subscribe(
        (tercero: Tercero) => {
          if (tercero.nit &&
            (tercero.TipoTercero.includes('Propietario') ||
              tercero.TipoTercero.includes('Arrendatario') ||
              tercero.TipoTercero.includes('Coo Propietario'))) {
            Swal.fire({
              icon: 'success',
              html: 'La verificación de tu identidad ha sido exitosa ¡Bienvenido!',
              showConfirmButton: false,
              timer: 3000
            });

            this.authService.crearTercero(tercero, this.enlace);
          } else {
            Swal.fire({
              icon: 'error',
              html: 'ERROR, no pudimos validar tu usuario y contraseña',
              showConfirmButton: false,
              timer: 3000
            });
          }
        },
        error => {
          Swal.fire({
            icon: 'error',
            html: 'ERROR, no pudimos validar tu usuario y contraseña',
            showConfirmButton: false,
            timer: 3000
          });
        },
        () => {
        }
      );
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      // email: ['', [Validators.required]],
      // password: ['', [Validators.required]],
      email: [''],
      nit: [''],
      password: [''],
    });
  }


}
