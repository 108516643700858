
import { Injectable } from '@angular/core';
// import { promise } from 'protractor';
import { Router } from '@angular/router';

import { LocalstorageService } from './localstorage.service';
import { SessionadminService } from './sessionadmin.service';
import { SessionempleadoService } from './sessionempleado.service';
import { SessionterceroService } from './sessiontercero.service';
import { Sessionadmin } from '../interfaces/sessionadmin';
import { Sessionempleado } from '../interfaces/sessionempleado';
import { Sessiontercero } from '../interfaces/sessiontercero';
import { Admin } from './../interfaces/admin';
import { Empleado } from './../interfaces/empleado';
import { Tercero } from './../interfaces/tercero';
import { QueryService } from './../../core/servicios/-query.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // public isUsuario = false;
  // tslint:disable-next-line: no-inferrable-types
  private isLoginObservable = new BehaviorSubject<boolean>(null);
  public isLogin$ = this.isLoginObservable.asObservable();

  constructor(
    private router: Router,
    private localstorageService: LocalstorageService,
    private sessionadminService: SessionadminService,
    private sessionempleadoService: SessionempleadoService,
    private sessionterceroService: SessionterceroService,
    private queryService: QueryService,
  ) { }


  public crearAdmin(admin: Admin): void {
    this.queryService.query_1002(admin.id, admin.usuario).subscribe(
      (sessionadmin: Sessionadmin) => {
        this.localstorageService.set('session_admin_com', sessionadmin);
        this.isLoginObservable.next(true);
        this.router.navigate(['/dashboard']);
      },
      error => {
      },
      () => {
      }
    );
  }

  public crearEmpleado(empleado: Empleado): void {
    this.queryService.query_1005(empleado.id, empleado.correoelectronico).subscribe(
      (sessionempleado: Sessionempleado) => {
        this.localstorageService.set('session_empleado_com', sessionempleado);
        this.isLoginObservable.next(true);
        this.router.navigate(['/dashboard']);
      },
      error => {
      },
      () => {
      }
    );
  }

  public crearTercero(tercero: Tercero, enlace: string): void {
    this.queryService.query_1007(tercero.nit, tercero.nombre, tercero.pnombre,
      tercero.snombre, tercero.papellido, tercero.sapellido,
      tercero.email1, tercero.direccion, tercero.telefono, 
      tercero.cel1, tercero.razonsocial, tercero.TipoTercero).subscribe(
        (sessiontercero: Sessiontercero) => {
          this.localstorageService.set('session_tercero_com', sessiontercero);
          this.isLoginObservable.next(true);
          if (enlace === 'peticion-new-peticion') {
            this.router.navigate(['/peticion/new-peticion']);
          } else if (enlace === 'cita-new-cita-virtual') {
            this.router.navigate(['/cita/new-cita/virtual']);
          } else if (enlace === 'cita-new-cita-presencial') {
            this.router.navigate(['/cita/new-cita/presencial']);
          } else if (enlace === 'desocupacion-new-desocupacion') {
            this.router.navigate(['/desocupacion/new-desocupacion']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        },
        error => {
        },
        () => {
        }
      );
  }

  // public async validar(): Promise<void> {
  public validar(): string {
    if (this.localstorageService.get('session_admin_com')) {
      this.isLoginObservable.next(true);
      return 'Admin';
    } else if (this.localstorageService.get('session_empleado_com')) {
      this.isLoginObservable.next(true);
      return 'Empleado';
    } else if (this.localstorageService.get('session_tercero_com')) {
      this.isLoginObservable.next(true);
      return 'Tercero';
    } else {
      this.isLoginObservable.next(false);
      return null;
    }
  }

  public obtenerAdmin(): Sessionadmin {
    if (this.localstorageService.get('session_admin_com')) {
      return this.localstorageService.get('session_admin_com');
    }
  }

  public obtenerEmpleado(): Sessionempleado {
    if (this.localstorageService.get('session_empleado_com')) {
      return this.localstorageService.get('session_empleado_com');
    }
  }

  public obtenerTercero(): Sessiontercero {
    if (this.localstorageService.get('session_tercero_com')) {
      return this.localstorageService.get('session_tercero_com');
    }
  }

  public async salir(): Promise<void> {
    if (this.localstorageService.get('session_admin_com')) {
      try {
        let sessionStorage: Sessionadmin = this.localstorageService.get('session_admin_com');
        this.sessionadminService.delete(sessionStorage).subscribe(
          (sessionadmin: Sessionadmin) => {
            this.localstorageService.clear();
            this.router.navigate(['/login']);
            this.isLoginObservable.next(false);
            return false;
          },
          error => {
            this.localstorageService.clear();
            this.router.navigate(['/login']);
            this.isLoginObservable.next(false);
            return false;
          },
          () => {
          }
        );
      } catch (e) {
      }
    } else if (this.localstorageService.get('session_empleado_com')) {
      try {
        let sessionStorage: Sessionempleado = this.localstorageService.get('session_empleado_com');
        this.sessionempleadoService.delete(sessionStorage).subscribe(
          (sessionempleado: Sessionempleado) => {
            this.localstorageService.clear();
            this.router.navigate(['/login']);
            this.isLoginObservable.next(false);
            return false;
          },
          error => {
            this.localstorageService.clear();
            this.router.navigate(['/login']);
            this.isLoginObservable.next(false);
            return false;
          },
          () => {
          }
        );
      } catch (e) {
      }
    } else if (this.localstorageService.get('session_tercero_com')) {
      try {
        let sessionStorage: Sessiontercero = this.localstorageService.get('session_tercero_com');
        this.sessionterceroService.delete(sessionStorage).subscribe(
          (sessiontercero: Sessiontercero) => {
            this.localstorageService.clear();
            this.router.navigate(['/login']);
            this.isLoginObservable.next(false);
            return false;
          },
          error => {
            this.localstorageService.clear();
            this.router.navigate(['/login']);
            this.isLoginObservable.next(false);
            return false;
          },
          () => {
          }
        );
      } catch (e) {
      }
    }
  }

}
